exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/DefaultPage.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-gallery-page-js": () => import("./../../../src/templates/GalleryPage.js" /* webpackChunkName: "component---src-templates-gallery-page-js" */),
  "component---src-templates-menu-page-js": () => import("./../../../src/templates/MenuPage.js" /* webpackChunkName: "component---src-templates-menu-page-js" */),
  "component---src-templates-start-page-js": () => import("./../../../src/templates/StartPage.js" /* webpackChunkName: "component---src-templates-start-page-js" */)
}

